var cosmosConfig = {}

cosmosConfig.endpoint = 'https://prod-bc-subsidy-cosmos-db.documents.azure.com:443/'
cosmosConfig.key = 'dE1fvDOdpd4yFOEFkfupEcVJyW9dxElMM3WqNQklClCJsCZW6BX8kMAzLe5XSs3XZXvhI9wG9SwoACDbIoUyZA=='

cosmosConfig.database = {
  id: 'prod-bc-db'
}

cosmosConfig.casesContainer = {
  id: 'cases'
}

cosmosConfig.bcContainer = {
  id: 'bargainingCouncils'
}

cosmosConfig.invoiceContainer = {
  id: 'invoices'
}

cosmosConfig.subsidyRatesContainer = {
  id: 'subsidyRates'
}

cosmosConfig.auditLogsContainer = {
  id: 'auditTrail'
}

module.exports = cosmosConfig